.h1 {
  @apply text-[26px] font-black;
}

.h2 {
  @apply text-[24px] font-black;
}

.h3 {
  @apply text-[22px] font-extrabold;
}

.h4 {
  @apply text-[20px] font-bold;
}

.h5 {
  @apply text-[18px] font-semibold mobile:text-[14px];
}

.h6 {
  @apply text-[16px] font-medium;
}

.p {
  @apply text-base;
}
