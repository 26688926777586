.authForm {
  @apply mx-auto text-center pt-10 mobile:py-10  mobile:bg-[#E1F1FF]  w-[520px];

  .flexCenter {
    @apply flex justify-center items-center select-none selection:bg-transparent;
  }

  .optionLogin {
    @apply text-[16px] text-[#484848] font-medium mb-5 mt-5 cursor-pointer;
  }
  .active {
    @apply text-primary border-b-2 border-primary;
  }

  .authFormCard {
    box-shadow: none;
    @apply mx-20 mobile:m-0;
    @screen mobile {
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    }
    @screen sm {
      @apply mx-0;
    }
  }
}
//bg-[url(../../../assets/login/bckLogin.png)] bg-no-repeat bg-left-top bg-cover
.authLeft {
  @apply mobile:hidden block  h-screen w-full;
}

@media (max-width: 425px) {
  .authForm {
    @apply w-full;
  }
}
