.hint {
  @apply absolute -top-[9rem] -right-[6rem] text-left text-xs bg-no-repeat bg-cover bg-center w-[160px] h-[131px] pl-[1.25rem] pt-[0.75rem];
  background-image: url('../../../assets/hint.png');
  z-index: 999;
  @screen mobile {
    @apply w-[147px] h-[141px] p-4 right-0;
    background-image: url('../../../assets/hint-mobile.png');
  }
  &::after {
    content: '';
    position: absolute;
    bottom: -20px;
    left: -20px;
  }
}
