/* The component style is inserted here. */
.card {
  @apply bg-white rounded-lg p-4 transition-all duration-500 ease-in-out;

  &.noBody {
    @apply p-0;
  }

  &.hover {
    @apply hover:shadow;
  }

  &.hasShadow {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  }

  &.borderCard {
    @apply border border-neutral-200;
  }
}
