.vertical {
  @apply flex justify-start items-start;
}

.horizotal {
}

.verticalButton {
}

.horizotalButton {
}
